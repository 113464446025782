@use 'theme' as *;

.redSearchEngine {
    width: 75%;
    @media only screen and (max-device-width: map-get($breakpoints, "xxl")) {
        width: 70%;
    }
}

.whiteSearchEngine {
    width: 100%;
}
